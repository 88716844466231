import Admin from "@/router/child/Admin";
import Client from "@/router/child/Client";
import {requireAuth} from "@/router/router-guards";

export default [
    {
        path: 'admin',
        props: true,
        component: () => import('../components/Admin/Main'),
        children: Admin,
        // beforeRouteEnter: requireAuth
        meta: {
            requireAdmin: true,
            title: 'dashboard',
        }
    },
    {
        path: 'admin/login',
        name: 'login',
        component: () => import('../views/admin/Login'),
        meta: {
            title: 'authorize'
        }
    },
    {
        path: '',
        props: false,
        component: () => import('../views/client/Index.vue'),
        children: Client,
        meta: {
            title: 'main'
        }
    }
];
