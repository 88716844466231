const common = {
    state: () => ({
        data: null
    }),
    mutations: {
        setData(state, data) {
            state.data = data;
        },
        deleteData(state) {
            state.data = null;
        }
    },
    actions: {},
    getters: {
        getData(state) {
            return state.data;
        }
    }
};

export default common;


