import Vue from 'vue';
import App from './App.vue';
import './assets/css/style.min.css';
import './assets/css/carousel.css';
import router from "@/router/index";
import store from "@/store/index";
import './plugins';
import Toast, {POSITION} from "vue-toastification";
import 'vue-toastification/dist/index.css';
import {TYPE} from "vue-toastification";
import VueMask from 'v-mask';
import infiniteScroll from 'vue-infinite-scroll'
import Multiselect from "vue-multiselect";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import VueSmoothScroll from 'vue2-smooth-scroll';
import "@/assets/css/styleV2.css"

Vue.use(VueMask);
Vue.use(infiniteScroll);
Vue.use(VueSmoothScroll);
Vue.component('multiselect', Multiselect)

Vue.config.productionTip = false;

const options = {
    position: POSITION.BOTTOM_RIGHT,
    hideProgressBar: true,
    icon: false,
    draggable: false,
    closeButton: false,
    timeout: 3000,
    toastDefaults: {
        [TYPE.ERROR]: {
            timeout: 1000
        },
    },
    bodyClassName: 'alert',
    closeOnClick: false
};

Vue.use(Toast, options);


router.beforeEach((to, from, next) => {
    // if (!store.state.auth.authorized && to.name !== 'login')
    //     next({name: 'login'});
    // else
        next();
});

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app');
