import { render, staticRenderFns } from "./ClientFooter.vue?vue&type=template&id=60672842&scoped=true&"
import script from "./ClientFooter.vue?vue&type=script&lang=js&"
export * from "./ClientFooter.vue?vue&type=script&lang=js&"
import style0 from "./ClientFooter.vue?vue&type=style&index=0&id=60672842&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60672842",
  null
  
)

export default component.exports