export default [
    // {
    //     path: '',
    //     name: 'courses',
    //     // component: () => import('../../views/client/courses/Index'),
    //     component: () => import('../../views/client/MainPage'),
    //     meta: {
    //         title: 'courses'
    //     }
    // },
    {
        path: '',
        // component: () => import('../../views/client/courses/Index'),
        component: () => import('../../views/client/MainPageV2'),
        children: [

            {
                path: '',
                name: 'courses',
                component: () => import('../../views/client/courses/CoursesList'),
                meta: {
                    title: 'courses'
                }
            },
            {
                path: 'news',
                name: 'news',
                component: () => import('../../views/client/News/Index'),
                meta: {
                    title: 'news'
                }
            },
            {
                path: 'webinars',
                name: 'webinars',
                component: () => import('../../views/client/Vebinars/Index'),
                meta: {
                    title: 'webinars'
                }
            },
        ]
    },
    {
        path: 'course/:id',
        name: 'course',
        component: () => import('../../views/client/courses/Item'),
        meta: {
            title: 'course'
        }
    },
    {
        path: 'course/:course_id/lesson/:lesson_id',
        name: 'lesson',
        component: () => import('../../views/client/lessons/Item'),
        meta: {
            title: 'lesson'
        }
    },
    {
        path: 'profile',
        name: 'profile',
        component: () => import('../../views/client/ProfileV2/ProfileMain'),
        // children: Profile,
        meta: {
            title: 'profile'
        }
    },
    {
        path: 'certificate/:id',
        name: 'certificate',
        component: () => import('../../views/client/Certificate'),
        meta: {
            title: 'certificate'
        }
    },
    {
        path: 'support',
        name: 'support',
        component: () => import('../../views/client/Support'),
        meta: {
            title: 'support'
        }
    },
    {
        path: 'course/:course_id/test',
        name: 'course-final-test',
        component: () => import('../../views/client/courses/Test'),
        meta: {
            title: "final_test"
        }
    },
    {
        path: 'oauth',
        name: 'oauth',
        component: () => import('../../views/client/Auth/Index'),
        meta: {
            title: ''
        }
    },
    {
        path: 'course/:id/finished_course',
        name: 'finished_course',
        component: () => import('../../components/FinishCourseAnimation'),
        meta: {
            title: ''
        }
    },
    {
        path: 'password-reset/:token',
        name: 'password_reset',
        component: () => import('../../views/client/Auth/PasswordReset'),
        meta: {
            title: ''
        }
    },
    {
        path: 'openai-chat',
        name: 'openai_chat',
        component: () => import('../../views/client/Chat'),
        meta: {
            title: 'chat'
        }
    },
    // {
    //     path: 'pinecone',
    //     name: 'pinecone',
    //     component: () => import('../../views/client/Chat2'),
    //     meta: {
    //         title: 'chat'
    //     }
    // },


];