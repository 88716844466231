<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div id="app">
        <auth-provider>
            <router-view>
                <template v-if="!$route.path.includes('admin')" v-slot:top>
<!--                    <Header v-if="!['lesson','course-final-test','oauth'].includes($route.name)"></Header>-->
                    <client-header  v-if="!['course-final-test','oauth'].includes($route.name)"/>
                </template>
            </router-view>
            <client-footer v-if="['courses','news','webinars'].includes($route.name)"/>
        </auth-provider>
    </div>
</template>

<script>
import AuthProvider from "@/providers/AuthProvider";
import client from "@/_api";
import ClientFooter from "@/views/client/components/ClientFooter";
import ClientHeader from "@/views/client/components/ClientHeader";

export default {
    name: 'App',
    components: {ClientHeader, ClientFooter, AuthProvider},
    methods: {
        getClientIp() {
            fetch('https://api.ipify.org/?format=json',
                {
                    method: 'GET',
                })
                .then(x => x.json())
                .then(({ip}) => {
                    client.defaults.headers.common['ClientIp'] = ip;
                });
        },

    },
    mounted() {
        this.getClientIp();
    }
};
</script>

<style>
/*
for mozilla checkboxes
 */
input[type=checkbox] {
    -moz-appearance: initial
}
</style>
