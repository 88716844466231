import Vue from 'vue';
import Router from 'vue-router';
import Localizer from '@/providers/LocalizationProvider';
import i18nService from "@/_services/i18n.service";
import {checkLocale} from "@/router/router-guards";
import routes from "@/router/routes";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: `/${i18nService.getCurrentLocale()}`,
        },
        {
            path: '/:locale',
            component: Localizer,
            beforeEnter: checkLocale,
            children: [...routes]
        }
    ]
});

const DEFAULT_TITLE = 'AstanaHub LMS';
router.afterEach((to, from, next) => {
    Vue.nextTick(() => {
        document.title = Vue.prototype.$t(to.meta.title) + " - AstanaHub LMS" || DEFAULT_TITLE;
    });

    next;
});
// router.beforeEach((to, from, next) => {
//
//     // const isAdmin = this.$store.getters[USER + "getUserFullname"] === 'administrator';
//     let isAdmin = store.getters["user/isAdmin"]
//     if (to.matched.some((record) => record.meta.requireAdmin)) {
//         if (!isAdmin) {
//             next({name: 'courses'});
//         } else {
//             next();
//         }
//     } else {
//         next();
//     }
// });

export default router;
