import Vue from "vue";
import vuexI18n from "vuex-i18n";
import store from "@/plugins/store";
import {locales} from "@/_configs/i18n";
import i18nService from "@/_services/i18n.service";
import DateRangePicker from 'vue2-daterange-picker';

Vue.use(vuexI18n.plugin, store);
Vue.use(DateRangePicker);

locales.forEach(locale => {
    Vue.i18n.add(locale.code, locale.dictionary);
});
Vue.i18n.set(i18nService.getCurrentLocale());
