import {requireAuth} from "@/router/router-guards";
import Settings from "./Settings";

export default [
    {
        path: '',
        name: 'dashboard',
        component: () => import('../../views/admin/dashboard/Index'),
        beforeRouteEnter: requireAuth,
        meta: {
            title: 'dashboard'
        }
    },
    // {
    //     path: 'login',
    //     name: 'login',
    //     component: () => import('../../components/Login')
    // },
    {
        path: 'courses',
        name: 'admin-courses',
        component: () => import('../../views/admin/courses/Index/Index'),
        meta: {
            title: 'courses'
        }
    },
    {
        path: 'course/:id',
        name: 'admin-course',
        component: () => import('../../views/admin/courses/Index/Item'),
        props: true,
        meta: {
            title: 'course'
        }
    },
    {
        path: 'course/:id/flow',
        name: 'flow-create',
        component: () => import('../../views/admin/courses/Index/Flow'),
        props: true,
        meta: {
            title: 'creating_flow'
        }
    },
    {
        path: 'course/:id/flow/:flow_id',
        name: 'flow-update',
        component: () => import('../../views/admin/courses/Index/Flow'),
        props: true,
        meta: {
            title: 'flow'
        }
    },
    {
        path: 'lesson/:id',
        name: 'admin-lesson',
        component: () => import('../../views/admin/lessons/LessonItem'),
        props: true,
        meta: {
            title: 'lesson'
        }
    },
    {
        path: 'settings',
        component: () => import('../../views/admin/settings/Settings'),
        beforeRouteEnter: requireAuth,
        children: Settings,
        meta: {
            title: 'settings'
        }
    },
    {
        path: 'course/:id/test',
        name: 'course-test',
        component: () => import('../../views/admin/tests/CourseTest'),
        meta: {
            title: 'final_test'
        }
    },
    {
        path: 'students',
        name: 'admin-students',
        component: () => import('../../views/admin/students/Index'),
        meta: {
            title: 'students'
        }
    },
    {
        path: 'students/:id',
        name: 'admin-student',
        component: () => import('../../views/admin/students/Item'),
        meta: {
            title: 'student'
        },
        props: true
    },
    {
        path: 'course/:id/interview',
        name: 'admin-interview',
        component: () => import('../../views/admin/courses/Index/Interview/Interview'),
        meta: {
            title: 'interview'
        }
    },
    {
        path: 'conferences',
        name: 'admin-conferences',
        component: () => import('../../views/admin/conference/Index'),
        meta: {
            title: 'video_conference'
        }
    },
    {
        path: 'archive',
        name: 'archive',
        component: () => import('../../views/admin/Archive/ArchiveIndex'),
        meta: {
            title: 'archive'
        }
    },
    {
        path: 'archive/lesson_template/:id',
        name: 'archive-lesson-template',
        component: () => import('../../views/admin/Archive/LessonTemplate/ArchiveLessonTemplateItem'),
        meta: {
            title: 'archive'
        }
    },
    {
        path: 'news',
        name: 'news-list',
        component: () => import('../../views/admin/News/NewsList'),
        meta: {}
    },
    {
        path: 'news/:id',
        name: 'news-item',
        component: () => import('../../views/admin/News/NewsItem'),
        meta: {}
    },
    {
        path: 'meet',
        name: 'meet-list',
        component: () => import('../../views/admin/Meet/MeetList'),
        meta: {}
    },
    {
        path: 'meet/:id',
        name: 'meet-item',
        component: () => import('../../views/admin/Meet/MeetItem'),
        meta: {}
    },
    {
        path: 'meet-create',
        name: 'meet-create',
        component: () => import('../../views/admin/Meet/MeetItem'),
        meta: {}
    },


];
