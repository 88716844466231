<template>
    <div>
        <slot/>
    </div>
</template>

<script>
import {setTokenToClient} from "@/_api";
import authService from "@/_services/auth.service";
import userService from "@/_services/user.service";

export default {
    name: "AuthProvider",
    methods: {
        getToken() {
            setTokenToClient(authService.getToken());
        },
        getUser() {
            setTokenToClient(authService.getToken());
            userService.getMyUser().then((user) => {
            }).catch(err => {
                authService.logout(false)
            });
        },
    },
    computed: {
        authorised() {
            return this.$store.state.auth.authorized;
        },
    },
    mounted() {
        if (this.authorised)
            if (this.authorised) {
                this.getUser();
            }
    },
    watch: {
        authorised(to) {
            if (to) {
                this.getUser();
            }
        }
    },
};
</script>

<style scoped>

</style>