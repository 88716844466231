import client, {handleResponse} from "@/_api/index";

const authApi = {
    login(email, password) {
        return client.post('/api/auth/login', {email, password}).then(handleResponse);
    },
    register(data) {
        return client.post('/api/auth/register', data).then(handleResponse);
    },
    logout() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({status: 200, data: {}});
            });
        });
    },
    forceLogin(id) {
        return client.post(`api/auth/force-login/${id}`).then(handleResponse);
    },
    oauthRequest(data) {
        return client.post('api/auth/get-user-data', data).then(handleResponse);
    },
    getRedirectUrl() {
        return client.get('api/auth/get-redirect-url').then(res => {
            return res.data;
        });
    },
    resetPassword(data) {
        return client.post('api/auth/reset-password', data).then(res => {
            return res.data;
        });
    },
    changePass(data) {
        return client.post('api/auth/change-password', data).then(res => {
            return res.data;
        });
    }
};

export default authApi;