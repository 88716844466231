import authApi from "@/_api/auth.api";
import {BAD_CREDENTIALS, LS_TOKEN, LS_USERS, NO_DATA} from '../_types';
import store from '../store/index';
import {AUTH, RESET, CLEAR, USER} from '../_types/store-types';

import {setTokenToClient, removeTokenFromClient} from "../_api";

const authService = {
    register(data) {
        return authApi.register(data).then(res => {
            return res;
        });
    },
    async login(email, password) {
        return await authApi.login(email, password)
            .catch(handleError)
            .then(handleSuccess)
            .then(({token}) => {
                this.setAuthorization(email, password, token);
            });
    },
    async forceLogin() {
        return await authApi.forceLogin(process.env.VUE_APP_LOCAL_USER_ID)
            .catch(handleError)
            .then(handleSuccess)
            .then(({token}) => {
                this.setAuthorization('test', 'local', token);
            });
    },
    getToken() {
        return localStorage.getItem(LS_TOKEN);
    },
    setAuthorization(phone, password, token) {
        const authdata = window.btoa(phone + ':' + encodeURIComponent(password));
        localStorage.setItem(LS_USERS, JSON.stringify(authdata));
        localStorage.setItem(LS_TOKEN, token);
        setTokenToClient(token);
        store.commit(AUTH + RESET);
    },
    async logout(bool = true) {
        return await authApi.logout().then(() => {
            removeTokenFromClient();
            localStorage.removeItem(LS_USERS);
            localStorage.removeItem(LS_TOKEN);
            store.commit(USER + CLEAR);
            store.commit(AUTH + RESET);
            if (bool)
                window.location.reload();
        });
    },
    oauthRequest(data) {
        return authApi.oauthRequest(data).then(res => {
            return res;
        });
    },
    getRedirectUrl() {
        return authApi.getRedirectUrl().then(res => {
            return res;
        });

    },
    resetPassword(data) {
        return authApi.resetPassword(data).then(res => {
            return res;
        });
    },
    changePass(data) {
        return authApi.changePass(data).then(res => {
            return res;
        });
    }
};

function handleError({message, response}) {
    const {status, data} = response;
    const errors = [];
    if (status === 400 && message === BAD_CREDENTIALS) {
        errors.push({status, message: 'wrong username or password'});
    } else if (status === 422) {
        Object.keys(data.data.errors).forEach(field => {
            data.data.errors[field].map((err, index) => {
                errors.push({status: field + '' + index, message: err});
            });
        });
    } else {
        errors.push({status, message});
    }
    throw errors;
}

function handleSuccess(res) {
    if (res.data) return res.data;
    throw {data: NO_DATA};
}

export default authService;