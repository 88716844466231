import store from '../store';
import {SET, USER} from '../_types/store-types';
import userApi from '../_api/user.api';
import {normalizeUser} from './normalizers';
import client, {handleError} from "@/_api";

const userService = {
    loadedUsers: [],
    getMyUser() {
        return userApi.getMyUser().then(({user}) => {
            user = normalizeUser(user);
            this.setUserToStore(user);
            return user;
        });
    },
    setUserToStore(user) {
        store.commit(USER + SET, user);
    },
    getUsers(data) {
        return userApi.getUsers(data).then(res => {
            if (res.data && res.data.length > 0) {
                for (let i = 0; i < res.data.length; i++) {
                    res.data[i] = normalizeUser(res.data[i]);
                }
            }
            return res;
        }).catch(err => {
            return err;
        });
    },
    createUser(data) {
        return userApi.createUser(data).then(res => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    deleteUser(id) {
        return userApi.deleteUser(id).then(res => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    uploadFile(data) {
        return userApi.uploadFile(data).then(res => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    updateUser(data) {
        return userApi.updateUser(data).then(res => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    uploadAvatar(data) {
        return userApi.uploadAvatar(data).then(res => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    tourView() {
        return userApi.tourView().then(res => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    userCourses() {
        return userApi.userCourses().then(res => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    notifications(data) {
        return userApi.notifications(data).then(res => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    notificationsViewed(data) {
        return userApi.notificationsViewed(data).then(res => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    invitationCourses() {
        return userApi.invitationCourses().then(res => {
            return res;
        }).catch(handleError);
    },
    applyToCourse(data){
        return userApi.applyToCourse(data).then(res => {
            return res;
        }).catch(handleError);
    }

};
window.userService = userService;

export default userService;