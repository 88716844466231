export default [
    {
        path: '/',
        name: 'settings',
        component: () => import('../../views/admin/settings/ListSettings'),
        meta: {
            title: 'settings'
        }
    },
    {
        path: 'files',
        name: 'files',
        component: () => import('../../views/admin/settings/Files/Index'),
        meta: {
            title: 'file_manager'
        }
    },
    {
        path: 'users',
        name: 'users',
        component: () => import('../../views/admin/settings/Users/Index'),
        meta: {
            title: 'users'
        }
    },
    {
        path: 'logs',
        name: 'logs',
        component: () => import('../../views/admin/settings/Logs/Index'),
        meta: {
            title: 'activity_log'
        }
    },
    {
        path: 'reports',
        name: 'reports',
        component: () => import('../../views/admin/settings/Reports/Index'),
        meta: {
            title: 'reports'
        }
    },
]