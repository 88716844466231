import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import user from './user'
import locale from './locale'
import common from './commonData'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    getters: {
    },
    modules: {
        auth,
        user,
        locale,
        common
    }
})
