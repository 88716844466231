export default {
    'lang': 'Қаз',
    'additional': 'Қосымша',
    'file': 'Файл',
    'course': 'Курс',
    'purpose': 'Мақсаты',
    'language': 'Тіл',
    'all_course': 'Барлық сабақтар',
    'all_language_versions': 'Барлық тіл нұсқалары',
    'total_files': 'Барлық файлдар',
    'file_manager': 'Файл менеджері',
    'uploaded_documents': 'Жүктелген құжаттар',
    'sign_out': 'Аккаунттан шығу',
    'fill_name': 'Атын жазыңыз',
    'cover': 'Мұқаба',
    'all_statuses': 'Барлық ахуалдар',
    'date_creation': 'Жасалған күні',
    'untitled': 'Аты жоқ',
    'timetable_of_classes': 'Сабақ кестесі',
    'enable_scheduled_opening_of_classes': 'Сабақтардың кесте бойынша ашылуын қосу',
    'name': 'Атауы',
    'completed': 'Аяқталды',
    'scheduled': 'Жоспарланды',
    'ended': 'Аяқталды',
    'total': 'Барлығы',
    'total_course': 'барлық курстар',
    'add_stream': 'Қосымша лек қосу',
    'language_versions': 'Тіл нұсқалары',
    'issuance_of_certificate': 'Сертификат шығару',
    'select_certificate_issuance': 'Сертификат шығару үшін кемінде бір шартты таңдаңыз',
    'complete_number_of_lesson': 'Сабақ санын аяқтау (%)',
    'success_final_test': 'Қорытынды сынақты сәтті өту',
    'deleting_course': 'Курс жойылуда',
    'delete_permanently': 'Біржолата жойғыңыз келе ме?',
    'all_permanently_deleted': 'Барлық қатысты құжаттар қайтарымсыз жойылатын болады',
    'publication': 'Жариялау',
    'lessons': 'сабақтар',
    'add_section': 'Бөлім қосу',
    'name_section': 'Бөлімнің атауы',
    'name_lesson': 'Сабақтың атауы',
    'deleting_lesson': 'Сабақты жою',
    'users': 'Пайдаланушылар',
    'access_rights_management': 'Рұқсат құқықтарын басқару',
    'total_users': 'Барлық пайдаланушылар саны',
    'name_user': 'Аты',
    'was_online': 'Желіде болған',
    'role': 'Рөл',
    'search_by_name_and_contact_details': 'Аты және байланыс деректері бойынша іздеу',
    'add_access': 'Рұқсат беру',
    'administrator': 'Әкімші',
    'filler_el_mail': 'Электрондық поштаңызды жазыңыз',
    'filler_lastname': 'Тегіңізді жазыңыз',
    'filler_firstname': 'Атыңызды жазыңыз',
    'filler_middlename': 'Әкеңіздің атын жазыңыз',
    'filler_phone': 'Ұялы телефон номерін жазыңыз',
    'email': 'Электрондық пошта',
    'lastname': 'Тегі',
    'middlename': 'Әкесінің аты',
    'phone': 'Ұялы телефон номері',
    'create_user': 'Пайдаланушыны құру',
    'published': 'Жарияланды',
    'not_published': 'Жарияланбады',
    'success': 'Сәтті',
    'published_s': 'Жарияланды',
    'not_published_s': 'Жарияланбады',
    'course_name': 'Курс аты',
    'course_name_fill': 'Курс атын жазыңыз',
    'course_card_description': 'Курс картасындағы сипаттама',
    'course_description_fill': 'Сипаттаманы толтырыңыз',
    'course_page_description': 'Курс парақшасындағы сипаттама',
    'img': 'Мұқаба',
    'status_all': 'Барлық статустар',
    'search_by_name': 'Аты бойынша іздеу',
    'date_created': 'Құрылған күні',
    'save': 'Сақтау',
    'no_name': 'Аты жоқ',
    'flow_name': 'Лектің аты',
    'flow_number': 'Лектің номері',
    'start_date': 'Басталу күні',
    'end_date': 'Аяқталу күні',
    'lesson_schedule': 'Сабақ кестесі',
    'disable_flow_schedule': 'Сабақтардың кесте бойынша ашылу қызметін қосу',
    'wrong_flow_dates': 'Лектің мезгілі дұрыс емес',
    'title_name': 'Атауы',
    'date': 'Күні',
    'status': 'Статус',
    'current': 'Қазіргі',
    'finished': 'Аяқталды',
    'planned': 'Жоспарланды',
    'started': 'Басталды',
    'finished_p': 'Аяқталды',
    'create_course': 'Курс жасау',
    '{n} course': 'Барлығы {n} курс ::: Барлығы {n} курс ::: Барлығы {n} курс',
    'description': 'Сипаттама',
    'content': 'Мазмұны',
    'statistics': 'Статистика',
    'students': 'Оқушылар',
    'flows': 'Лектер',
    'settings': 'Теңшелімдер',
    'russian': 'Орысша',
    'kazakh': 'Қазақша',
    'is_published': 'Публикация',
    'certificate_pass': 'Сертификатты беру',
    'certificate_pass_description': 'Сертификат алу үшін кемінде бір шартты таңдаңыз',
    'finish_lesson_percent': '(%) Сабақ аяқтау',
    'success_test_pass': 'Қорытынды тестті сәтті өту',
    'complete_survey': 'Сабақтардың 100% орындау, қорытынды сынақтан өтү (бар болса), курстың соңында сауалнама өтү',
    'pass_cert_on_flow': 'Лек біткен соң сертификат беру',
    'delete_course': 'Курсты жою',
    'delete_course_c': 'Курсты жою',
    'delete_confirm': 'Қайтарымсыз жою керек пе?',
    'delete_confirm_ext': 'Барлық қатысты құжаттар да қайтарымсыз жойылады',
    'cancel': 'Болдырмау',
    'delete': 'Жою',
    'create_lesson': 'Сабақ құру',
    'add_module': 'Бөлім қосу',
    'module_name': 'Бөлім атауы',
    '{n} lesson': 'Барлығы {n} сабақ ::: Барлығы {n} сабақ ::: Барлығы {n} сабақ',
    'task': 'Тапсырма',
    'lang_versions': 'Тіл нұсқалары',
    'lesson_name': 'Сабақ атауы',
    'delete_lesson': 'Сабақтың жоюлуы',
    'delete_lesson_b': 'Сабақты жою',
    'courses': 'Курстар',
    'add_flow': 'Лек қосу',
    'link_to_telegram_chat_for_participants': 'Қатысушыларға арналған Telegram-чатқа сілтеме',
    'lesson_text': 'Сабақтың тексті',
    'load': 'Жүктеу',
    'material': 'Материал',
    'attach_material': 'Материал қосу',
    'add': 'Қосу',
    'attached_materials': 'Бекітілген материалдар',
    'select_type': 'Түрді таңдаңыз',
    'link': 'Сілтеме',
    'select_file': 'Файл таңдау',
    'file_loading': 'Файл жүктелуде...',
    'open': 'Ашу',
    'rename': 'Атын өзгерту',
    'support': 'Көмек',
    'details': 'Толығырақ',
    'index_authorize': 'Платформаға кiру үшін авторизациядан өтіңіз',
    'index_authorize_desc': 'Курс өтіңіз, іс-шараларға қатысыңыз, балл жинаңыз, жинаған баллды тауар дүкенінде жаратыңыз',
    'index_authorize_button': 'Кіру',
    'add_media_to_question': 'Сұраққа медиа қосу',
    'add_media': 'Медиа қосу',
    'delete_question': 'Сұрақты жою',
    'delete_media': 'Медианы жою',
    'final_test': 'Қорытынды тестілеу',
    'write_answer': 'Жауап жазу',
    'load_file': 'Файлды жүктеу',
    'add_answer': 'Нұсқа қосу',
    'answer_option': 'Жауап нұсқалары',
    'set_write_answer': 'Дұрыс жауап таңдау',
    'add_group': 'Топты қосу',
    'group_sort': 'Топ бойынша сараптау',
    'first_elem': 'Бірінші элемент',
    'second_elem': 'Екінші элемент',
    'edit': 'Өзгерту',
    'tests': 'Тестілеу',
    'tests_description': 'Жауап нұсқалары бар сұрақтар және автоматты тексеруі бар басқа да тапсырмалар',
    'questioning': 'Сауалнамалау',
    'questioning_description': 'Қолмен тексерілетін ашық сұрақтар',
    'load_file_test': 'Файл жүктелуде',
    'load_file_test_description': 'Қолмен тексерілетін оқушылардың құжаттары жүктелуде',
    'add_question': 'Сұрақ қосу',
    'dashboard': 'Бақылау тақтасы',
    'edit_user': 'Қолданушыны өзгерту',
    'upload_avatar': 'Аватар жүктеу',
    'delete_user': 'Қолданушыны жою',
    'password': 'Құпиясөз',
    'password_confirmation': 'Құпиясөзді қайталаңыз',
    'final_test_toggle': 'Қорытынды тестілеуді бастау',
    'associate_pairs': 'Жұптарды байланыстыру',
    'not_set': 'Толтырылмаған',
    'lesson_video': 'Сабақ видеосы',
    'file_locale': 'Медиа локализациясы',
    'file_locale_desc': 'Қазіргі тіл нұсқасы үшін медиа ауыстырылды',
    'delete_material': 'Материалды жою',
    'mail': 'Пошта',
    'authorize': 'Кіру',
    'incorrect_login_or_password': 'Қате логин немесе құпиясөз',
    'flow': 'Лек',
    'apply': 'Өтінім беру',
    'person_has_been_trained': 'адам оқудан өтті',
    'your_application_for_consideration': 'Сіздің өтініміңіз қарастырылуда',
    'your_application_has_been_rejected': 'Сіздің өтініміңіз қабылданбады',
    'application_submitted': 'Өтінім жіберілді',
    'review_days': '7 жұмыс күнінің ішінде қарастырылады',
    'telegram_chat_for_course_participants': 'Telegram-чат',
    'open_question': 'Ашық сұрақ',
    'option': 'Нұсқа',
    'group': 'Топ',
    'group_name': 'Топтың атауы',
    'group_element': 'Топтың элементі',
    'task_description': 'Тапсырманың сипаттамасы',
    'notification': 'Хабарландырулар',
    'certificate': 'Сертификаттар',
    'achievement': 'Жетістіктер',
    'tour_slide_0_title': 'Кош келдіңіз',
    'tour_slide_0_desc': 'Платформаның негізгі мүмкіндіктері жайында біліңіз',
    'tour_slide_1_title': 'Оқуды бастаңыз',
    'tour_slide_1_desc': 'Курстардың біріне өтінім беріңіз де, сабақтарды ашыңыз',
    'tour_slide_2_title': 'Алға өтіңіз',
    'tour_slide_2_desc': 'Жаңа білім алыңыз, тапсырмаларды орындаңыз және балл жинаңыз',
    'tour_slide_3_title': 'Сертификат алыңыз',
    'tour_slide_3_desc': 'Оқуды аяқтап, курсты өткеніңіз жайлы сертификат алыңыз',
    'skip': 'Өтіп кету',
    'next': 'Келесі',
    'complete': 'Аяқтау',
    'points_per_question': 'бір сұраққа балл',
    'sent': 'Жіберілді',
    'finish_lesson': 'Сабақты аяқтау',
    'attach_link': 'Сілтемені бекіту',
    'file_loaded': 'Файл жүктелді',
    'load_presentation': 'Презентацияны жүктеңіз',
    'on_review': 'Тексерілуде',
    'in_progress': 'Жұмыс барысында',
    'study_application': 'Оқуға өтінім',
    'infinite': 'Шексіз',
    'attempts_amount': 'Талпыныс саны',
    'time_limit': 'Уақыт шегі',
    'no_limit': 'Шектелу жоқ',
    'pass_percent': 'Өту табалдырығы (%)',
    'certificate_is_valid': 'Сертификат жарамды',
    'certificate_number': 'сертификаттың',
    'student': 'Оқушы',
    'date_of_issue': 'Берілген күні',
    'download_certificate': 'Сертификатты жүктеу',
    'certificate_not_found': 'Сертификат табылмады',
    'total_students': 'барлық оқушылар саны',
    'completed_their_studies': 'оқуды тәмамдады',
    'message': 'Хабарлама',
    'your_email': 'Сіздің электрондық поштаңыз',
    'your_message': 'Сіздің хабарламаңыз',
    'creating_flow': 'Лек құрылуда',
    'n from n': '{total} -дан {got}  ',
    'show_answers': 'Жауаптарды көру',
    'hide_answers': 'Жауаптарды жасыру',
    'not_finished': 'Істелмеген',
    'failed': 'Өткен жоқсыз',
    'passed': 'Өттіңіз',
    '{n} questions': 'Барлығы <b>{n}</b> сұрақ ::: Барлығы <b>{n}</b> сұрақ ::: Барлығы <b>{n}</b> сұрақ',
    'set_answer': 'Жауап беру',
    'start_test': 'Тестті бастау',
    'answer_later': 'Кейін жауап беру',
    'go_to_website': 'Сайтқа өту',
    'check_errors': 'Қателерді көру',
    'restart': 'Қайталау',
    'get_correct {n}': 'келесіге өту үшін, кемінде {n} сұраққа дұрыс жауап беріңіз.',
    '{n} attempts': '<b>{n}</b> әрекет ::: <b>{n}</b> әрекет ::: <b>{n}</b> әрекет',
    'test_text': 'Сізде {time} және {attempts}.',
    'test_text_time': 'Сізде {time} және <b>шексіз</b> әрекет мүмкіндігі бар.',
    'test_text_attempts': 'Сізде<b>шектелмеген</b> уақыт және {attempts}.',
    '{n} minutes': '<b>{n}</b> минут ::: <b>{n}</b> минут ::: <b>{n}</b> минут',
    'no limits': 'Сізде <b>шектелмеген</b> уақыт және <b>шексіз</b> әрекет саны бар.',
    'attempts': '{n} әрекет ::: {n} әрекеттер ::: {n} әрекет',
    'continue': 'Жалғастыру',
    'error_change_course': 'Оқу кезінде курсты өзгертуге болмайды',
    'file_link': 'Файлға сілтеме',
    'lesson': 'Сабақ',
    'finished_lesson': 'Сабақты өттіңіз',
    'finished_tasks': 'Тапсырманы орындадыңыз',
    'finished_course': 'курсты тәмамдадыңыз',
    'started_course': 'курсты бастадыңыз',
    'all_flows': 'Барлық лектер',
    'course_progress': 'Оқуды жалғастыру • {n}%',
    'open_profile': 'Профильды ашу',
    'registration': 'Тіркеу',
    'date_of_registration': 'Тіркелу күні',
    '{n} students': 'Барлығы {n} оқушы ::: Барлығы {n} оқушы ::: Барлығы {n} оқушы',
    'asc': 'көбею бойынша',
    'desc': 'азаю бойынша',
    'status_passed': 'Өтілді',
    'course_link': 'Курсқа өтінім сілтемесі',
    'progress': 'Прогресс',
    'courses_completed': 'курс өтілді',
    'failed_auth': 'Сәтсіз',
    'add_student': 'Оқушы қосу',
    'search': 'Іздеу',
    'already_attached': 'Берілген оқушы курсқа тіркелінген',
    'no_active_flow': 'Белсенді ағындар жоқ',
    'main': 'Басты бет',
    'course_no_link': 'Өтінім формасы жоқ',
    'guide': 'Басқарма',
    'file_load_tab': 'Жүктеу',
    'open_questions': 'Ашық сұрақтар',
    'accept': 'Қабылдау',
    'revision': 'Тексеру',
    'send_to_review': 'Тапсырманы тексеріске жіберу',
    'comment': 'Комментарий',
    'set_comment': 'Коментарий жазыңыз',
    'lesson_list': 'Сабақтар тізімі',
    'open_date': 'Ашылған күні',
    'task_not_checked': 'Тапсырма тексерілмеген',
    'not_passed': 'Өтпеді',
    'has_not_checked_questions': 'Тексерілмген тапсырмалар бар',
    'reason': 'Себеп',
    'decline': 'Бас тарту',
    'not_accepted': 'Қабылданбады',
    'reception_closed': 'Қабылдау жабық',
    'no_attempts': 'Тест бұрын өтілмеген',
    'not_finished_lesson': 'Сабақ аяқталмаған',
    'accepted': 'Қабылданды',
    'declined': 'Қабылданбады',
    'completed_the_lessons': 'сабақты аяқтады',
    'started_the_lessons': 'сабақты бастады',
    'close': 'Жабу',
    'paste': 'Қою',
    'back': 'Артқа',
    'timer': 'Таймер',
    'logout': 'Шығу',
    'error': 'Қате',
    'remove': 'Кетіру',
    'loading': 'Жүктелуде',
    'passed_status': 'Орындалды',
    'on_revision': 'Тексерілуде',
    'tasks_results': 'Тапсырма жауаптары',
    'finished_at': 'Аяқталған уақыты',
    'lesson_finished': 'Аяқталды',
    'mark_answer': 'Жауапты бағалаңыз',
    'show_lesson_test_results': 'Сабақ тесттерінің дұрыс жауаптарын көру',
    'show_final_test_results': 'Қорытынды сабақ тестінің жауабын көру',
    'reviewed': 'Тексерілді',
    'failed_course': 'Қорытынды тест сәтсіз аяқталды.',
    'failed_course_description': 'Өкінішке орай, дұрыс жауаптар саны жеткіліксіз. Келесі оқу ағынында тағы да тапсырып көруіңізге болады.',
    'course_failed': 'Сәтсіз.',
    'test_fail_alert': 'Қорытынды тест сәтсіз болған жағдайда, сіз курстан шығарыласыз.',
    'not_all_correct_answers_selected': 'Барлық дұрыс жауап белгіленбеді',
    'points': 'Ұпайлар',
    'activity_log': 'Белсенділік журналы',
    'action': 'Әрекеттер',
    'user': 'Қолданушы',
    'date_and_time': 'Күні және уақыты',
    'user_deleted': 'Қолданушы өшірілді',
    'user_created': 'Қолданушы құрылды',
    'test_question_update': 'Тест сұрағы жаңартылды',
    'test_question_deleted': 'Тест сұрағы өшірілді',
    'test_question_created': 'Тест сұрағы құрылды',
    'test_answer_deleted': 'Тест жауабы өшірілді',
    'test_answer_created': 'Тест жауабы құрылды',
    'test_answer_update': 'Тест жауабы жаңартылды',
    'test_created': 'Тест құрылды',
    'test_update': 'Тест жаңартылды',
    'task_question_update': 'Тапсырма сұрағы жаңартылды',
    'task_question_created': 'Тапсырма сұрағы құрылды',
    'task_created': 'Тапсырма құрылды',
    'task_update': 'Тапсырма жаңартылды',
    'material_update_kk': 'Қазақша материал жаңартылды',
    'material_update_ru': 'Орысша материал жаңартылды',
    'material_created': 'Материал құрылды',
    'material_deleted': 'Материал өшірілді',
    'lesson_update_ru': 'Сабақ жаңартылды',
    'lesson_update_kk': 'Сабақ жаіңартылды',
    'lesson_template_update_ru': 'Курс жаңартылды',
    'lesson_template_update_kk': 'Курс жаңартылды',
    'lesson_template_update': 'Курс жаңартылды',
    'template_module_created': 'Тарау құрылды',
    'template_module_update': 'Тарау жаңартылды',
    'template_module_deleted': 'Тарау өшірілді',
    'lesson_template_deleted': 'Курс өшірілді',
    'lesson_template_created': 'Курс құрылды',
    'lesson_deleted': 'Сабақ өшірілді',
    'lesson_created': 'Сабақ құрылды',
    'lesson_update': 'Сабақ жаңартылды',
    'flow_created': 'Ағын құрылды',
    'flow_deleted': 'Ағыл өшірілді',
    'flow_update': 'Ағын жаңартылды',
    'lesson_open_date_created': 'Сабақтың ашылған күні құрылды',
    'lesson_open_date_updated': 'Сабақтың ашылған күні жаңартылды',
    'deleted': 'өшірілді',
    'admin_created': 'Әкімші құрылды',
    'student_created': 'Оқушы құрылды',
    'viewed_notifications': 'қаралған хабарландырулар',
    'task_checked_lesson': 'Тапсырма тексерілді, сабақ: "{name}" ',
    'task_checked_course': 'Тапсырма тексерілді, курс: "{name}" ',
    'application_accepted': 'Өтініш қабылданды, курс: "{name}" ',
    'students_passed': 'оқушы өтті',
    'avg_attempts': 'орт. әрекеттер саны',
    'questions': 'Сұрақтар',
    'status_not_checked': 'Тексерілмеді',
    'result': 'Нәтиже',
    'attempts_singular': 'Әрекеттер',
    'flow_ended': 'Ағын аяқталды',
    'flow_ended_description': 'Өкінішке орай, курсты уақытында аяқтай алмадыңыз. Келесі ағында, тағы да тапсырып көрсеңіз болады!',
    'flow_ended_status': 'Мерзімі өткен',
    'course_passed': 'Курсты аяқтады',
    'curator': 'Куратор',
    'no_permission': 'Рұқсат жоқ',
    'edit_file': 'Файлды өзгерту',
    'edit_link': 'Сілтемені өзгерту',
    'test_title': 'Тестілеу',
    'quiz_at_the_end_of_the_course': 'Курс соңындағы сауалнама',
    'enable_end_of_course_poll': 'Курс соңындағы сауалнаманы қосу',
    'interview': 'Сұхбат',
    'select_answer': 'Жауапты таңдау',
    'raiting': 'Бағалау',
    'question_description': 'Сұрақ сипаттамасы',
    'multiple_choice': 'Бірнеше жауапты таңдаңыз',
    'results': 'Нәтиже',
    'max_points': 'максималды ұпайлар',
    'video_conference': 'Видеоконференция',
    'create': 'Құру',
    'course_and_flow': 'Курс және ағын',
    'date_and_time_event': 'Іс-шара куні мен уақыты',
    'join_link': 'Қосылуға сілтеме',
    'enter_title': 'Тақырыпты енгізіңіз',
    'choose_course': 'Курсты таңдаңыз',
    'total_conference': 'Барлық видеоконференциялар',
    'date_event': 'Іс-шара күні',
    'select_flow': 'Ағынды таңдаңыз',
    'active_students': 'белсенді студенттер',
    'online': 'онлайн',
    'deadline_access': 'Курсты қол жетімді ету',
    'end_date_access': 'Курс дейін қол жетімді',
    'deadline_access_closed': 'Осы күні, курсқа кіру автоматты түрде жабылады',
    'set_access': 'Қол жетімді ету',
    'today': 'Бүгін',
    'no': 'Жоқ',
    'send_notification': 'Хабарландыру жіберу',
    'subject': 'Тақырып',
    'subject_description': 'Тақырып сипаттамасы',
    'text': 'Мәтін',
    'text_description': 'Мәтін сипаттамасы ',
    'send': 'Жібері',
    'english': 'Ағылшын',
    'file_not_selected': 'Файл енгізілмеді',
    'auto_check': 'Автоматты тексеру',
    'manual_check': 'Қолма-қол тексеру',
    'completion_skills': 'Игерілген қабілеттер',
    'skill_name': 'Қабілет атауы',
    'add_skill': 'Қабілет қосу',
    'skills': 'Қабілеттер',
    'show_more': 'Тағы көрсету',
    'set_badge': 'Төсбелгі беру',
    'no_achievements': 'Жетістіктер жоқ',
    'reports': 'Баяндамалар',
    'reports_description': 'Баяндама даярлауға өтініш қалдыру',
    'report_all_types': 'Баяндаманың барлық түрлері',
    'report_ready': 'Аяқталды',
    'queue': 'Кезекте',
    'students_report': 'Оқушылар туралы баяндама',
    'refresh': 'Жаңарту',
    'download': 'Жүктеу',
    'create_date': 'Даярланған күн бойынша({text})',
    'you_didnt_pass_test': 'Сіз тесттен өтпедіңіз',
    'interview_results': 'Сұхбат нәтижелері',
    'in_lesson': 'Сабақта',
    'download_files_without_questions': 'Сұрақтарсыз файлдарды жүктеу',
    'test_without_questions': 'Сұрақтарсыз тесттер бар',
    'survey_without_questions': 'Сұрақтарсыз сұхбаттар бар',
    'included_interview': 'Сұрақтарсыз сұхбат курсқа енгізілген',
    'final_testing_included': 'Сұрақтарсыз қорытынды тест курсқа енгізілген',
    'problems_in_lesson': 'Сабақ бойынша мәселе бар',
    'problems_in_course': 'Курс бойынша мәселе бар',
    'attached_materials_s': 'Тіркелген материал',
    'next_lesson': 'Келесі сабақ',
    'create_course_copy': 'Курс көшірмесін жасау',
    'copy_course': 'Сделать копию курса?',
    'copy_course_descr': 'Копирование курса займет время. Пожалуйста, ожидайте. ',
    'course_materials': 'Бонусные материалы',
    'course_client_materials': 'Эксклюзивный бонус от команды Astana Hub',
    'prev_lesson': 'Алдыңғы сабақ',
    'finish_course': 'Курсты аяқтау',
    'finish_interview': 'Сауалнаманы аяқтау',
    'congrats': 'Құттықтаймыз!',
    'tagline': 'Слоган',
    'lesson_amount': 'Бейне сабақтардың саны',
    'apply_to_course': 'Тіркелу',
    'test_descr': 'Тестілеу теорияны нығайтуға және прогресті бағалауға көмектеседі.\n' +
        '\n' +
        'Қателесуден қорықпаңыз, Сізде бірнеше мүмкіндік бар. Курсты аяқтау үшін {total} сұрақтан {questions} жауап беріңіз.\n' +
        '\n' +
        'Сәттілік! ',
    'test_success': 'Құттықтаймыз! Сіз курсты аяқтадыңыз!\n' +
        '\n' +
        'Сертификат алу үшін сауалнамадан өтіңіз.',
    'success_interview': 'Кері байланыс үшін рахмет!',
    'certificate_': 'Сертификат',
    "iin": "ЖСН",
    'archive': 'Архив',
    'restore_student': 'Восстановить студента',
    'student_data': 'Данные ученика',
    'restore_course': 'Восстановить курс',
    'register': 'Тіркелу',
    'directions': 'Салалар',
    'restore_password_success': 'Құпиясөзді жаңарту үшін поштаңызға хат жіберілді.',
    'notif_text': 'Сізге тегін курс қол жетімді, оны алу үшін түймесін басыңыз!',
    'notif_button': 'Алу',
    'notif_success': 'Сіздің өтінішіңіз сәтті қабылданды! ✅',
    'notif_error': 'Кешіріңіз, бірақ сіз тегін шақыруды қолдандыңыз. Жаңа тегін курсты алу үшін сол бағытта жүріңіз! 👍',
    'notif_1': 'Сіздерді 2 курстың сәтті аяқталуымен құттықтаймыз! 🌟 Біз сізге тағы бір қызықты курсқа тегін қол жеткізуге қуаныштымыз. Құттықтаймыз және сіздің IT мансабыңызда көптеген қызықты жаңалықтар мен табыстар тілейміз! 🚀',
    'free_course': 'Тегін курс алынды',
    'telegram_bot_button': 'Telegram-бот',
    'courses_list': "Курстар каталогы",
    'hello_name': "Сәлем {name}! ✌",
    'course_search': 'Курстарды іздеу',
    'entrepreneurship': 'Кәсіпкерлік',
    'freelance': 'Фриланс',
    'career': 'Мансап',
    'news': 'Жаңалықтар',
    'create_news': 'Жаңалықтар жасау',
    '{n} news': 'Барлығы {n} жаңалық ::: барлығы {n} жаңалықтар ::: Барлығы {n} жаңалықтар',
    'hide_finished_lessons': 'Өткен сабақтарды жасыру',
    'started_first_lesson': 'Бірінші сабақты бастады',
    'continue_video': 'Бейнені жалғастыру {time}',
    'section_1': 'Досыңды әкел де, жеңілдік ал',
    'section_2': 'Акцияға қатысыңыз',
    'section_3': 'Платформа туралы',
    'section_4': 'LMS платформасы-Ақпараттық технологиялар (IT) және технологиялық кәсіпкерлік саласындағы түрлі білім беру бағдарламалары мен курстарына қолжетімділікті қамтамасыз ететін Астана Хаб білім беру платформасы.',
    'section_5': 'Платформаның артықшылықтары',
    'section_6': 'Икемді оқу кестесі, платформа ыңғайлы оқу мүмкіндіктерін ұсынады',
    'section_7': 'IT саласына жылдам кіру үшін арналған оқыту бағыттары ',
    'section_8': 'Өзініздің қалаған уақытынызда және кез келген жерде үйреніңіз',
    'section_9': 'Үлкен тәжірибесі бар сарапшылардан үйреніңіз',
    'section_10': 'Ыңғайлы платформа',
    'section_11': 'Кімге сәйкес келеді?',
    'section_12': 'Жаңадан бастаушылар',
    'section_13': 'IT саласына кіргісі келетіндер үшін',
    'section_14': 'Тәжірибесі бар оқушыларға',
    'section_15': 'IT саласына кіргісі келетіндер үшін',
    'section_16': 'Қызмет саласын өзгерткелі жатқандарға',
    'section_17': 'IT саласына икемді және жылдам қосылуға көмектесетін жаңа бағыттар',
    'section_18': 'Оқушылар мен студенттерге',
    'progress_course': '{n}% материал өтті',
    'continue_study': 'Оқуды жалғастыру',
    'all': 'Барлығы',
    'task_comment': 'Түсініктер',
    'contacts': 'Байланыс',
    'not_authorized': 'Авторизациясыз қосылған оқушылар',
    'module': "Модуль",
    'courses_report': 'Курстар туралы есеп',
    'section_19': '«GameDev саласындағы кәсіптер картасы» курсы',
    'section_20': 'Өтініш беру'

};
