import client from '@/_api/index';

const userApi = {
    getMyUser() {
        return client.get('/api/user/info').then(res => {
            return res.data.data;
        });
    },
    getUsers(data) {
        return client.get('/api/users', {params: data}).then(res => res.data);
    },
    createUser(data) {
        return client.post('/api/user/create', data).then(res => res.data);
    },
    uploadFile(data) {
        return client.post('/api/upload-file', data).then(res => res.data);
    },
    deleteUser(id) {
        return client.delete('/api/user/' + id + '/delete').then(res => res.data);
    },
    updateUser(data) {
        return client.put('/api/profile', data).then(res => res.data);
    },
    uploadAvatar(data) {
        return client.post('/api/profile/avatar_upload', data).then(res => res.data);
    },
    tourView() {
        return client.post('/api/tour-view').then(res => res.data);
    },
    userCourses() {
        return client.get('/api/user-courses').then(res => res.data);
    },
    notifications(data) {
        return client.get('api/user/notifications', {params: data}).then(res => res.data);
    },
    notificationsViewed(data) {
        return client.post('api/user/notifications-viewed', data).then(res => res.data);
    },
    invitationCourses() {
        return client.get('api/user/invitations').then(res => res.data);
    },
    applyToCourse(data) {
        return client.post('api/user/apply', data).then(res => res.data);
    }

};

export default userApi;